import { mapActions, mapGetters } from 'vuex'
import { showVueToast } from '@/utils'
import VueCookies from 'vue-cookies'
const LoginForm = () => import(/* webpackChunkName: "login-form" */ '@/components/forms/Login')
const LoginForRedeemForm = () => import(/* webpackChunkName: "login-form" */ '@/components/forms/LoginForRedeemForm')

export default {
  name: 'login',
  components: {
    LoginForm,
    LoginForRedeemForm
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    targetUrl() {
      return this.$route.query.ref
    },
    isRedirectAfterRedeem() {
      return this.targetUrl === '/prakerja/redeem'
    },
    environtment() {
      return process.env.NODE_ENV
    }
  },
  methods: {
    ...mapActions('auth', ['getCurrentUser', 'removeUserData']),
    setUserData(response, state) {
      if (this.environtment === 'development') {
        VueCookies.set('token', response.token)
      } else {
        VueCookies.set('token', response.token, null, null, '.g2academy.co')
      }
      const utm = [localStorage.getItem('utm_campaign'), localStorage.getItem('utm_medium'), localStorage.getItem('utm_source')]
      localStorage.clear()
      if (utm[0]) {
        localStorage.setItem('utm_campaign', utm[0])
      }
      if (utm[1]) {
        localStorage.setItem('utm_medium', utm[1])
      }
      if (utm[2]) {
        localStorage.setItem('utm_source', utm[2])
      }
      let isRoleStudent = false
      // save customerRoles to localstorage if exist
      if (response?.customerRoles?.length) {
        const customerEmployee = this.checkCustomerEmpolyee(response?.customerRoles)
        localStorage.setItem('customer_employee', customerEmployee)
      }
      switch (state) {
        case 'student':
          localStorage.setItem('access_token', response.token)
          localStorage.setItem('user_name', response.name)
          localStorage.setItem('user_id', response.id)
          isRoleStudent = this.isStudent(response.roles)
          break
        case 'alliance':
          localStorage.setItem('token', response.token)
          localStorage.setItem('mitraId', response.mitraId)
          break
      }
      this.getCurrentUser()
      if (this.targetUrl) {
        this.$router.push(this.targetUrl)
        return
      }
      switch (state) {
        case 'student':
          isRoleStudent ? this.$router.push('/user/my-classes') : this.errorMessage('Your account is not found!')
          break
        case 'alliance':
          this.$router.push('/partner-profile')
          break
      }
    },
    errorMessage(message) {
      this.removeUserData()
      showVueToast(message, 'error', 3000)
    },
    isStudent(roles) {
      const status = roles.includes('STUDENT')
      if (status) {
        localStorage.setItem('user_roles', 'STUDENT')
        return true
      }
      return false
    },
    checkCustomerEmpolyee(customerRoles) {
      let employee = null
      const G2User = customerRoles.filter((user) => user?.customer?.id === 1)[0]
      if (G2User) {
        const isEmployee = G2User?.roles.includes('EMPLOYEE')
        employee = isEmployee ? 1 : null
      }
      return employee
    }
  }
}
